.clientContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutUsContainer {
  width: 90vw;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 50px;
}

.ourClientsContainer {
  width: 90vw;
  padding-left: 20px;
  padding-right: 20px;
}

.ourServicesContainer {
  width: 90vw;
  padding-left: 20px;
  padding-right: 20px;
}

.clientImagesContainer {
  padding-bottom: 30px;
}

.clientImages {
  width: 200px;
  height: auto;
  padding-right: 30px;

  @media (max-width: 768px) {
    width: 150px;
    height: auto;
    padding-right: 10px;
  }
}
