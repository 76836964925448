.container {
  padding: 50px;

  @media (max-width: $mantine-breakpoint-md) {
    max-width: 100%;
    margin-right: 0;
  }
}

.ongoingAuctionContainer {
  padding-top: 50px;
  padding-bottom: 20px;
}

.ongoingAuctionTitle {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 20px;
}

.upcomingAuctionContainer {
  padding-top: 50px;
  padding-bottom: 20px;
}

.upcomingAuctionTitle {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 20px;
}

.pastAuctionContainer {
  padding-top: 50px;
  padding-bottom: 20px;
}

.pastAuctionTitle {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 20px;
}

.registerBtnFont {
  font-size: 14px;
}

.viewBtnFont {
  font-size: 14px;
}

@media (max-width: 500px) {
  .ongoingAuctionTitle {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .upcomingAuctionTitle {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .pastAuctionTitle {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .registerBtnFont {
    font-size: 12px;
  }

  .viewBtnFont {
    font-size: 12px;
  }
}

@media (max-width: 380px) {
  .registerBtnFont {
    font-size: 10px;
  }

  .viewBtnFont {
    font-size: 10px;
  }
}
