.inner {
  display: flex;
  justify-content: space-between;
  padding-top: calc(var(--mantine-spacing-xl) * 4);
  padding-bottom: calc(var(--mantine-spacing-xl) * 4);
  margin-bottom: 100px;
}

.content {
  max-width: rem(480px);
  margin-right: calc(var(--mantine-spacing-xl) * 3);

  @media (max-width: $mantine-breakpoint-md) {
    max-width: 100%;
    margin-right: 0;
  }
}

.title {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-family: Greycliff CF, var(--mantine-font-family);
  font-size: rem(44px);
  line-height: 1.2;
  font-weight: 900;

  @media (max-width: $mantine-breakpoint-xs) {
    font-size: rem(28px);
  }
}

.howItWorksContainer {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: static;
  margin-bottom: 100px;
}

.control {
  @media (max-width: 768px) {
    flex: 1;
  }
}

.image {
  width: rem(356px);
  height: rem(336px);
  margin-left: 80px;
}

.list {
  padding: 20px;
}

@media (max-width: 768px) {
  /* Adjust the max-width value as per your definition of a medium screen */
  .image {
    display: none;
  }
}
